import { useState, useEffect } from "react";
import { Box, Flex, Radio, Text } from "@mantine/core";

type Props = {
  value: string;
  addItems: (val: any) => void;
};

const LanguageSetting = ({ value, addItems }: Props) => {
  const langs = ["English", "Spanish", "Germany"];
  const [selectedOption, setSelectedOption] = useState(langs[0]);

  useEffect(() => {
    const current = langs.find((lang: string) => lang === value);

    if (current) {
      setSelectedOption(current);
    }
  }, [value]);

  const updateContext = (val: string) => {
    addItems({ language: val });
    setSelectedOption(val);
  };

  return (
    <Box p="lg" w="100%">
      <Text pb="md" size="lg" fw={700} color="navy.0">
        Language
      </Text>

      <Radio.Group value={selectedOption} onChange={updateContext}>
        <Flex direction="column" w="100%">
          {langs.map((option) => (
            <Radio
              key={option}
              value={option}
              label={
                <Text
                  pl={8}
                  fw={option === selectedOption ? 700 : 400}
                  color="navy.0"
                  sx={{ cursor: "pointer", wordBreak: "break-word" }}
                >
                  {option}
                </Text>
              }
              py={16}
              px={32}
              w="100%"
              color="navy.0"
            />
          ))}
        </Flex>
      </Radio.Group>
    </Box>
  );
};

export default LanguageSetting;

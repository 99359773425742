import {
  FC,
  Fragment,
  KeyboardEvent,
  useState,
  useMemo,
  useEffect,
} from "react";
import { Flex, Button, Center, Input, Text } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "store";
import { sendMessage, receiveMessage } from "store/slices/pdfSlice";
import useWebSocket from "react-use-websocket";
import { Api } from "apis";
import { Some } from "utils";

import MessageBox from "./MessageBox";
import DefaultMessageBox from "./DefaultMessageBox";
import MessageLoading from "./MessageLoading";
import scrollChat from "./helpers/scrollChat";
import SendIcon from "assets/icons/SendIcon";

interface Props {
  name: string;
}

let TIMEOUT: ReturnType<typeof setTimeout>;

const ChatSection: FC<Props> = ({ name }) => {
  const [loading, setLoading] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [botMessage, setBotMessage] = useState("");
  const [isReceiving, setIsReceiving] = useState(false);
  const {
    pdfReducer,
    settingReducer: { settings },
  } = useSelector<StoreState, StoreState>((state) => state);

  const dispatch = useDispatch();

  // dont include pdfReducer in dependecy array
  useEffect(() => {
    if (pdfReducer.pdfs.length >= 0) {
      setPdfLoaded(pdfReducer.comparePdfs.some((guy) => guy.name === name));
    }
  }, [name]);

  const { sendJsonMessage } = useWebSocket(Api.socketUrl, {
    onOpen: () => console.log("WebSocket connection established."),
    onMessage: (resp) => {
      const dataString = Some.String(resp?.data);
      console.log(dataString);

      if (
        dataString.includes("Connection established") ||
        dataString.includes("Provide pdf_name first") ||
        dataString.includes("is_function_calling_model")
      )
        return;

      if (dataString.includes("index loaded for pdf")) {
        return setTimeout(() => {
          dispatch(receiveMessage({ name, text: "PDF Loaded successfully" }));
          setPdfLoaded(true);
          setLoading(false);
          scrollChat();
        }, 800);
      }

      if (dataString === "<EOD>") {
        setBotMessage((prevMsg) => {
          setIsReceiving(false);
          dispatch(receiveMessage({ name, text: prevMsg }));
          scrollChat();
          return "";
        });
      } else {
        if (!isReceiving) {
          setIsReceiving(true);
          scrollChat();
        }
        setBotMessage((msg) => msg + dataString);
      }
    },
  });

  const messages = useMemo(() => {
    const pdfs = pdfReducer.pdfs;
    const messages = pdfs.find((guy) => guy.name === name)?.messages;
    return messages || [];
  }, [pdfReducer, name]);

  const [messageText, setMessageText] = useState("");

  function submitMessage() {
    if (messageText.length < 1) return;

    const pdfs = pdfReducer.comparePdfs.find((guy) => guy.name === name);
    if (pdfs?.id) {
      sendJsonMessage({ compare_query: messageText });
    } else sendJsonMessage({ query: messageText });
    dispatch(sendMessage({ text: messageText, name }));
    setMessageText("");
    scrollChat();
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event.shiftKey && event.code === "Enter") {
      event.preventDefault();
      return setMessageText(messageText + "\n");
    }
    if (event.key === "Enter") {
      event.preventDefault();
      submitMessage();
    }
  }

  useEffect(() => {
    if (settings && name) {
      if (TIMEOUT) clearTimeout(TIMEOUT);
      TIMEOUT = setTimeout(() => {
        sendJsonMessage({ settings: settings });
      }, 2000);
    }
  }, [settings, name, sendJsonMessage]);

  return (
    <Flex
      h="100%"
      w="100%"
      direction="column"
      bg="white"
      sx={{
        borderTopLeftRadius: "25px",
      }}
    >
      {name.length > 0 ? (
        <Fragment>
          <Flex
            id="chat-container"
            mih={0}
            sx={{ flexGrow: 1, overflowY: "auto" }}
            direction="column"
            gap={35}
            p="md"
          >
            <DefaultMessageBox
              name={name}
              sendJsonMessage={sendJsonMessage}
              loading={loading}
              setLoading={setLoading}
              pdfLoaded={pdfLoaded}
              settings={settings}
            />
            {messages.map((message) => (
              <MessageBox key={message.id} message={message} name={"User"} />
            ))}
            {isReceiving && <MessageLoading />}
          </Flex>
          <Center p="md">
            <Input
              w="85%"
              disabled={!pdfLoaded}
              placeholder={
                pdfLoaded
                  ? "Type your Message"
                  : "Load PDF by clicking 'Start Analysis'"
              }
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              onKeyDown={onKeyDown}
              sx={{
                border: 0,
                "& .mantine-Input-input": {
                  minHeight: "3.5rem",
                  px: 1,
                },
                "& .mantine-Input-rightSection": {
                  p: "5px",
                },
              }}
              rightSectionWidth={"5rem"}
              rightSection={
                <Button
                  onClick={submitMessage}
                  variant="subtle"
                  color="navy.0"
                  sx={{
                    // width: 86,
                    // height:68,
                    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
                    borderRadius: "5px",
                    background:
                      "linear-gradient(124deg, #7431D7 0%, #9D38DD 100%)",
                    "&:hover": { backgroundColor: "transparent" },
                    "& svg": { width: 25, height: 25 },
                  }}
                >
                  <SendIcon />
                </Button>
              }
            />
          </Center>
        </Fragment>
      ) : (
        <Center h="100%">
          <Text fw="bold" size="xl" align="center" color="navy.0">
            Please choose a PDF first
          </Text>
        </Center>
      )}
    </Flex>
  );
};

export default ChatSection;

import { createLogger } from "redux-logger";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./slices";
import { mySaga } from "store/sagas";

const logger = createLogger({ diff: false, logErrors: true, duration: true });
const sagaMiddleware = createSagaMiddleware();
let middlewares:any=[]
middlewares.push(sagaMiddleware);
middlewares.push(logger);
middlewares.push(thunk);


const persistConfig = { key: "root", storage };
const persistedReducer = persistReducer(persistConfig, rootReducer);



export const store = configureStore({
  reducer:persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: middlewares,
});

sagaMiddleware.run(mySaga);

export type StoreState = ReturnType<typeof rootReducer>;
export const persistor = persistStore(store);

import { FC } from "react";
import { Flex, Avatar, Box, Text } from "@mantine/core";

import { Message } from "store/slices/pdfSlice";
import { BotIcon } from "assets/icons";

interface Props {
  message: Message;
  name: string;
}

const MessageBox: FC<Props> = ({ message, name }) => {
  return (
    <Box maw="80%" ml={message.from === "BOT" ? undefined : "auto"}>
      <Flex
        gap={16}
        w="fit-content"
        direction={message.from === "BOT" ? "row" : "row-reverse"}
      >
        <Avatar
          sx={{
            width: 45,
            height: 45,
            border: "1px solid #EDEDED",
            color: "#FFF",
            borderRadius: "50%",
          }}
        >
          {message.from === "BOT" ? <BotIcon /> : name.charAt(0)}
        </Avatar>
        <Box
          p={16}
          sx={{
            // border: 1,
            // borderStyle: "solid",
            // borderColor: colors.gray[4],
            borderRadius: 8,
            background:
              message.from === "BOT"
                ? "linear-gradient(226deg, rgba(232, 240, 250, 0.45) 0%, rgba(237, 235, 250, 0.45) 100%)"
                : "rgba(255, 255, 255, 0.45)",
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Text color="navy.0">{message.text}</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default MessageBox;

import { MantineProvider } from "@mantine/core";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "store";
import Index from "pages/index";
import Setting from "context/setting";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            // fontFamily: "Open Sans",
            colors: {
              navy: [
                "#2C4860",
                "#4F6A83",
                "#728DA8",
                "#98B3CF",
                "#BEDAF7",
                "#E6F0ED",
                "#EDF4ED",
                "#E9F0FA",
                "#fafefb",
              ],
              extra: ["#ebf8f4", "#eff1f3"],
            },
          }}
        >
          <Setting>
            <Index />
          </Setting>
        </MantineProvider>
      </PersistGate>
    </Provider>
  );
}

import React from "react";

const SendIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_368_1152"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="29"
        height="29"
      >
        <path d="M29 0H0V29H29V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_368_1152)">
        <path
          d="M3.72295 16.4657L12.1823 14.7738C12.9377 14.6229 12.9377 14.3773 12.1823 14.2264L3.72295 12.5344C3.21907 12.4338 2.72834 11.9426 2.62774 11.4392L0.935775 2.97982C0.784431 2.22401 1.22985 1.87284 1.92993 2.19591L27.744 14.1099C28.2103 14.3252 28.2103 14.675 27.744 14.8902L1.92993 26.8042C1.22985 27.1273 0.784431 26.7761 0.935775 26.0203L2.62774 17.5609C2.72834 17.0575 3.21907 16.5663 3.72295 16.4657Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default SendIcon;

import React from "react";

const UploadIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.2035 5.35047L8.15 3.39498V11.5485C8.15 11.774 8.23955 11.9903 8.39896 12.1497C8.55837 12.3092 8.77457 12.3987 9 12.3987C9.22543 12.3987 9.44164 12.3092 9.60104 12.1497C9.76045 11.9903 9.85 11.774 9.85 11.5485V3.39498L11.7965 5.35047C11.8755 5.43016 11.9695 5.49341 12.0731 5.53657C12.1767 5.57974 12.2878 5.60196 12.4 5.60196C12.5122 5.60196 12.6233 5.57974 12.7269 5.53657C12.8305 5.49341 12.9245 5.43016 13.0035 5.35047C13.0832 5.27143 13.1464 5.1774 13.1896 5.07379C13.2327 4.97019 13.2549 4.85906 13.2549 4.74682C13.2549 4.63458 13.2327 4.52345 13.1896 4.41985C13.1464 4.31624 13.0832 4.22221 13.0035 4.14317L9.6035 0.742322C9.52266 0.664918 9.42734 0.604243 9.323 0.563777C9.11606 0.478741 8.88394 0.478741 8.677 0.563777C8.57266 0.604243 8.47734 0.664918 8.3965 0.742322L4.9965 4.14317C4.91725 4.22244 4.85438 4.31655 4.81149 4.42013C4.7686 4.5237 4.74652 4.63471 4.74652 4.74682C4.74652 4.85893 4.7686 4.96994 4.81149 5.07351C4.85438 5.17709 4.91725 5.2712 4.9965 5.35047C5.07575 5.42974 5.16984 5.49263 5.27339 5.53553C5.37694 5.57843 5.48792 5.60051 5.6 5.60051C5.71208 5.60051 5.82306 5.57843 5.92661 5.53553C6.03016 5.49263 6.12425 5.42974 6.2035 5.35047ZM16.65 10.6983C16.4246 10.6983 16.2084 10.7879 16.049 10.9473C15.8896 11.1068 15.8 11.323 15.8 11.5485V14.9494C15.8 15.1749 15.7104 15.3911 15.551 15.5506C15.3916 15.71 15.1754 15.7996 14.95 15.7996H3.05C2.82457 15.7996 2.60837 15.71 2.44896 15.5506C2.28955 15.3911 2.2 15.1749 2.2 14.9494V11.5485C2.2 11.323 2.11045 11.1068 1.95104 10.9473C1.79163 10.7879 1.57543 10.6983 1.35 10.6983C1.12457 10.6983 0.908365 10.7879 0.748959 10.9473C0.589553 11.1068 0.5 11.323 0.5 11.5485V14.9494C0.5 15.6258 0.76866 16.2746 1.24688 16.7529C1.7251 17.2313 2.3737 17.5 3.05 17.5H14.95C15.6263 17.5 16.2749 17.2313 16.7531 16.7529C17.2313 16.2746 17.5 15.6258 17.5 14.9494V11.5485C17.5 11.323 17.4104 11.1068 17.251 10.9473C17.0916 10.7879 16.8754 10.6983 16.65 10.6983Z"
        fill="white"
      />
    </svg>
  );
};

export default UploadIcon;

import { FC, Fragment, ReactElement, createElement, Children } from "react";
import { Box, BoxProps } from "@mantine/core";

interface MatchProps extends BoxProps {
  test: string | number;
  component?: typeof Box;
}

/* This is just a fancy Switch case statement for rendering components
 * <Match
 *   test="second"
 *   component={Stack} // Now its a Stack with matching component as child
 *   // any number of StackProps can now be provided
 * >
 *   <Match.Case value="first">
 *     <First />
 *   </Match.Case>
 *   <Match.Case value="second">
 *     <Second />
 *   </Match.Case>
 *   <Match.Case value="third">
 *     <Third />
 *   </Match.Case>
 * </Match> */
function Match({ test, component, children, ...rest }: MatchProps) {
  const child = Children.toArray(children).find((kid) => {
    if (typeof kid === "string" || typeof kid === "number") return false;
    if ("props" in kid) return kid?.props?.value === test;
    return false;
  });
  return createElement(component || Fragment, component ? rest : {}, child);
}

type CaseProps = { value: string | number; children: ReactElement };
const Case: FC<CaseProps> = ({ children }) => children;

Match.Case = Case;

export default Match;

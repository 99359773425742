import {combineReducers} from 'redux'


import pdfSlice from './pdfSlice'
import userSlice from './userSlice' 
import settingSlice from './settingSlice'


const rootReducer=combineReducers({
    pdfReducer:pdfSlice,
    userReducer:userSlice,
    settingReducer:settingSlice
})

export default rootReducer
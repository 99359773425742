import { useMantineTheme, Select, Box } from "@mantine/core";
import DropdownIcon from "assets/icons/DropdownIcon";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "store";
import { addItems } from "store/slices/settingSlice";

const data = [
  {
    value: "gpt-3.5-turbo",
    label: "GPT 3.5",
  },
  {
    value: "gpt-4",
    label: "GPT 4",
  },
];

const SelectGpt = () => {
  const { colors } = useMantineTheme();

  const [model_name, setModelName] = useState<"gpt-3.5-turbo" | "gpt-4">("gpt-3.5-turbo");

  const dispatch = useDispatch();
  const {
    settingReducer: { settings: state },
  }: any = useSelector<StoreState, StoreState>((state) => state);

  const handleAddItems = (val: any) => {
    dispatch(addItems(val));
  };

  useEffect(() => {
    setModelName(state.model);
  }, [state.model]);

  const handleChange = (val: string) => {
    setModelName(val as "gpt-3.5-turbo" | "gpt-4");
    handleAddItems({ model: val });
  };

  return (
    <Select
      size="md"
      ml="lg"
      value={model_name}
      data={data}
      onChange={handleChange}
      w={250}
      color="navy.0"
      rightSectionWidth={"3rem"}
      rightSection={<DropdownIcon />}
      sx={{
        "& input": {
          fontWeight: "bold",
          color: colors.navy[0],
          border: "none",
          borderRadius: "10px",
          background: "#DFE9FD",
          zIndex: 9999,
        },
      }}
    />
  );
};

export default SelectGpt;

import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { updateAt } from "utils";

const BOT = "BOT";
const USER = "USER";

type Recipient = "BOT" | "USER";

export type Message = {
  text: string;
  senderName: string;
  from: Recipient;
  to: Recipient;
  id: string;
};

interface PdfGuy {
  id: string;
  name: string;
  messages: Array<Message>;
}

function _createPdf(name: string): PdfGuy {
  return { id: nanoid(), name, messages: [] };
}

function _resetState<T>(state: T): T {
  return { ...state, messages: [] };
}

const initialState: {
  pdfs: PdfGuy[];
  isLoading: boolean;
  comparePdfs: PdfGuy[];
} = {
  pdfs: [],
  isLoading: true,
  comparePdfs: [],
};

function _getNewPdfs(
  oldGuys: Array<PdfGuy>,
  newGuys: Array<string>,
  comparePdfs: Array<PdfGuy> = []
) {
  let allGuys: Array<PdfGuy> = [];
  newGuys.forEach((name) => {
    let guy = oldGuys.find((guy) => guy.name === name);
    allGuys.push(guy || _createPdf(name));
  });
  return [...allGuys, ...comparePdfs];
}

function _getNewComparePdfs(oldGuys: Array<PdfGuy>, name: string) {
  let allGuys: Array<PdfGuy> = [];
  let guy = oldGuys.find((guy) => guy.name === name);
  allGuys.push(guy || _createPdf(name));
  return allGuys;
}

const pdfSlice = createSlice({
  name: "pdfSlice",
  initialState,
  reducers: {
    getPdfs: (state) => ({ ...state, isLoading: true }),
    setPdfs: (state, action) => ({
      ...state,
      pdfs: _getNewPdfs(
        state.pdfs,
        action.payload.sort((a: string, b: string) => a.localeCompare(b)),
        state.comparePdfs
      ),
      comparePdfs: [],
      isLoading: false,
    }),
    sendMessage: (state, action) => {
      function _updatePdfs<T extends Array<PdfGuy>>(pdfs: T) {
        return updateAt(
          pdfs,
          (guy) => guy.name === action.payload.name,
          (guy) => ({
            ...guy,
            messages: guy.messages.concat({
              text: action.payload.text,
              from: USER,
              to: BOT,
              senderName: action.payload.name,
              id: nanoid(),
            }),
          })
        );
      }

      return {
        ...state,
        pdfs: _updatePdfs(state.pdfs),
        comparePdfs: _updatePdfs(state.comparePdfs),
      };
    },
    receiveMessage: (state, action) => ({
      ...state,
      pdfs: updateAt(
        state.pdfs,
        (guy) => guy.name === action.payload.name,
        (guy) => ({
          ...guy,
          messages: guy.messages.concat({
            text: action.payload.text,
            from: BOT,
            to: USER,
            senderName: BOT,
            id: nanoid(),
          }),
        })
      ),
      comparePdfs: updateAt(
        state.comparePdfs,
        (guy) => guy.name === action.payload.name,
        (guy) => ({
          ...guy,
          messages: guy.messages.concat({
            text: action.payload.text,
            from: BOT,
            to: USER,
            senderName: BOT,
            id: nanoid(),
          }),
        })
      ),
    }),
    setComparePdf: (state, action) => {
      const comparePdfs = _getNewComparePdfs(state.comparePdfs, action.payload);
      const pdfs = _getNewPdfs(
        state.pdfs,
        state.pdfs.map((guy: PdfGuy) => guy.name),
        comparePdfs
      );
      return {
        ...state,
        isLoading: false,
        pdfs,
        comparePdfs,
      };
    },
    clearChats: (state) => ({
      ...state,
      pdfs: state.pdfs.map(_resetState),
      comparePdfs: state.comparePdfs.map(_resetState),
    }),
  },
});

export type PdfState = typeof initialState;
export const {
  getPdfs,
  setPdfs,
  sendMessage,
  receiveMessage,
  setComparePdf,
  clearChats,
} = pdfSlice.actions;
export default pdfSlice.reducer;

import { useDisclosure } from "@mantine/hooks";
import { Avatar, Menu, Flex, Text, useMantineTheme, Box } from "@mantine/core";
import { IconSettings, IconTrashX } from "@tabler/icons-react";
import { useDispatch } from "react-redux";

// local imports
import SelectPersona from "./SelectPersona";
import { SettingsModal } from "components";
import { clearChats } from "store/slices/pdfSlice";

const UserMenu = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { colors } = useMantineTheme();

  const dispatch = useDispatch();

  return (
    <>
      <Menu shadow="md" width={300} position="bottom-end">
        <Menu.Target>
          <Avatar
            size="lg"
            sx={{
              border: 1,
              borderStyle: "solid",
              borderColor: colors.gray[5],
              borderRadius: "50%",
            }}
          >
            U
          </Avatar>
        </Menu.Target>
        <Menu.Dropdown>
          <Box sx={{ borderBottom: "1px solid #EBEBEB" }} p={"1rem"}>
            <Text size="sm" my={"1rem"}>
              Choose Chatbot Persona
            </Text>
            <SelectPersona />
          </Box>
          <Menu.Item onClick={open}>
            <Flex gap={8}>
              <IconSettings size={30} color="black" stroke={1.5} />
              <Text size="lg">Settings</Text>
            </Flex>
          </Menu.Item>
          <Menu.Item onClick={() => dispatch(clearChats())}>
            <Flex gap={8}>
              <IconTrashX size={30} color="black" stroke={1.5} />
              <Text size="lg">Clear chats</Text>
            </Flex>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <SettingsModal opened={opened} onClose={close} />
    </>
  );
};

export default UserMenu;

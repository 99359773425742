import { useMantineTheme, Select, Box } from "@mantine/core";
import DropdownIcon from "assets/icons/DropdownIcon";
import { SettingContext } from "context/setting";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "store";
import { addItems } from "store/slices/settingSlice";

const data = [
  {
    value: "financial",
    label: "Bond Analyst",
  }
];

const SelectPersona = () => {
  const { colors } = useMantineTheme();

  const [Persona, setPresona] = useState<"financial" >("financial");

  const dispatch = useDispatch();
  const {
    settingReducer: { settings: state },
  }: any = useSelector<StoreState, StoreState>((state) => state);

  const handleAddItems = (val: any) => {
    dispatch(addItems(val));
  };

  useEffect(() => {
    setPresona(state.persona || "financial");
  }, [state.persona]);

  const handleChange = (val: string) => {
    setPresona(val as "financial");
    handleAddItems({ persona: val });
  };

  return (
    <Select
      size="md"
      value={Persona}
      data={data}
      onChange={handleChange}
      w={250}
      color="navy.0"
      rightSectionWidth={"3rem"}
      rightSection={<DropdownIcon />}
      sx={{
        "& input": {
          fontWeight: "bold",
          color: colors.navy[0],
          border: "none",
          borderRadius: "10px",
          background: "#EAEBF8",
          zIndex: 9999,
        },
      }}
    />
  );
};

export default SelectPersona;

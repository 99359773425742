import axios from "axios";
import { apiPromise } from "./helpers";

let instance = axios.create({
  headers: { "Content-Type": "application/json; charset=utf-8" },
});

export class Api {
  static baseUrl = "https://development-llm-api.prr.ai/bond_llm";
  static socketUrl = "wss://development-llm-api.prr.ai/bond_llm/chat_bot";

  static getUsers() {
    return apiPromise(() =>
      instance.get("https://jsonplaceholder.typicode.com/users")
    );
  }

  static getPdfs() {
    return apiPromise(() => instance.get(this.baseUrl + "/namespaces"));
  }

  static uploadPdf(formData: FormData) {
    return apiPromise(() =>
      instance.post(this.baseUrl + "/upload_pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    );
  }
}

import { FC } from "react";
import {
  Flex,
  Avatar,
  Box,
  Text,
  Button,
  useMantineTheme,
} from "@mantine/core";

import { BotIcon } from "assets/icons";
import { Settings } from "store/slices/settingSlice";

interface Props {
  name: string;
  sendJsonMessage: (obj: any) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  pdfLoaded: boolean;
  settings: Settings;
}

const DefaultMessageBox: FC<Props> = ({
  name,
  sendJsonMessage,
  loading,
  setLoading,
  pdfLoaded,
  settings,
}) => {
  const { colors } = useMantineTheme();

  function onClick() {
    setLoading(true);
    sendJsonMessage({ pdf_name: name,settings});
  }

  return (
    <Flex gap={16} w="80%">
      <Avatar
        sx={{
          width: 59,
          height: 45,
          border: "1px solid #EDEDED",
          borderRadius: "50%",
        }}
      >
        <BotIcon />
      </Avatar>
      <Box
        p={16}
        bg="navy.7"
        sx={{
          border: 1,
          borderStyle: "solid",
          borderColor: colors.gray[4],
          borderRadius: 8,
        }}
      >
        <Text color="navy.0">
          Welcome to our PDF Chatbot!
          <br />
          I'm here to assist you with any questions or discussions related to
          your PDF files.
          <br />
          <br />
          Whether you need information, analysis, or a comparison between
          multiple PDFs, I've got you covered. Simply upload or select a PDF,
          and let's dive into the content.
          <br />
          <br />
          If you have any specific questions or topics you'd like to explore,
          feel free to let me know. I'm here to make your PDF experience
          seamless and informative. How can I assist you today?
        </Text>
        <Button
          loading={loading}
          onClick={onClick}
          radius="md"
          mt="lg"
          color="navy.0"
          disabled={pdfLoaded}
        >
          Start Analysis
        </Button>
      </Box>
    </Flex>
  );
};

export default DefaultMessageBox;

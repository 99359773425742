import { createSlice } from "@reduxjs/toolkit";



export type Settings={
  temperature: number,
  top_k: number,
  language: string,
  memory: number,
  model: string;
  persona:string;  
}

export interface SettingsState {
  settings: Settings;
}

const initialState:SettingsState = {
  settings: {
    temperature: 0,
    top_k: 5,
    language: 'English',
    memory: 5,
    model: 'gpt-3.5-turbo',
    persona:'financial'
  }
};

const settingSlice = createSlice({
  name: "settingSlice",
  initialState,
  reducers: {
    addItems: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
  },
});

export const { addItems } = settingSlice.actions;
export default settingSlice.reducer;

import React, { useReducer } from 'react'


type initial_state = {
    changed: boolean
}

type Iprops = {
    children: React.ReactNode
}

export const initialState: initial_state = {
    changed: false
}

const reducer = (state: initial_state, action: any) => {
    switch (action.type) {
        case 'set_state':
            const value = action.payload;
            return { ...state, ...value }
        default:
            return state;
    }
}

export const SettingContext = React.createContext({})

const Setting = ({ children }: Iprops) => {

    const [state, dispatch] = useReducer(reducer, initialState)

    const values = React.useMemo(() => ({
        state,
        addItems: (value: any) => {
            dispatch({ type: 'set_state', payload: value })
        }
    }), [state])
    
    return (
        <SettingContext.Provider value={values}>
            {children}
        </SettingContext.Provider>
    )
}

export default Setting
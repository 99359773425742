import { useEffect, useState } from "react";
import { Box, Flex, Text, Slider } from "@mantine/core";

type Props = {
  value: number;
  addItems: (val: any) => void;
};

const TemperatureSetting = ({ value, addItems }: Props) => {
  const [value1, setValue] = useState<number>(0);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (val: number) => {
    addItems({ temperature: val });
    setValue(val);
  };

  return (
    <Box p="lg" w="100%">
      <Text pb="md" size="lg" fw={700} color="navy.0">
        Temperature
      </Text>
      <Flex
        p="md"
        bg="extra.0"
        w="100%"
        sx={{ borderRadius: 16, backgroundColor: "#E9F0FA" }}
        direction="column"
      >
        <Flex align="end" justify="space-between" w="100%">
          <Text>0.00</Text>
          <Text pb="lg" size="xl" fw="bold" color="navy.0">
            {value1}
          </Text>
          <Text>1.00</Text>
        </Flex>
        <Slider
          label={null}
          color="navy.0"
          precision={2}
          min={0}
          max={1}
          step={0.01}
          value={value1}
          onChange={handleChange}
        />
        <Text mt="lg" size="sm" align="center" color="navy.0">
          The temperature setting adjusts the speed and accuracy of responses.
          <br />
          <br />
          Lower values prioritize accuracy, while higher values prioritize
          faster responses with potentially reduced accuracy
        </Text>
      </Flex>
    </Box>
  );
};

export default TemperatureSetting;

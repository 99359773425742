function scrollChat() {
  setTimeout(() => {
    const container = document.getElementById("chat-container");
    if (container)
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
  }, 500);
}

export default scrollChat;

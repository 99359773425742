import { useState, useEffect } from "react";
import { AppShell, Center } from "@mantine/core";

import { useSelector, useDispatch } from "react-redux";

import { Api } from "apis";
import { Sidebar, Header, ChatSection } from "components";
import { StoreState } from "store";
import { getPdfs, setComparePdf } from "store/slices/pdfSlice";

let TIMEOUT: ReturnType<typeof setTimeout>;

const Index = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { pdfReducer } = useSelector<StoreState, StoreState>((state) => state);
  const options = pdfReducer.pdfs.map((guy) => guy.name);
  const [selectedOption, setSelectedOption] = useState(options.at(0) || "");

  useEffect(() => {
    if (TIMEOUT) clearTimeout(TIMEOUT);
    TIMEOUT = setTimeout(() => dispatch(getPdfs()), 100);
  }, [dispatch]);

  async function uploadPdf(file: File | null) {
    if (!file) return;
    let formData = new FormData();
    formData.append("pdf_file", file);
    const resp = await Api.uploadPdf(formData);
    console.log(resp);
  }

  const addCompanreAndContrast = (name: string) => {
    dispatch(setComparePdf(name));
    setSelectedOption(name);
  };

  return (
    <AppShell
      padding={0}
      bg="linear-gradient(110.08deg, #E6F3FA -3.63%, #F0E9FA 94.88%)"
      navbarOffsetBreakpoint="sm"
      navbar={
        <Sidebar
          open={open}
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          addCompareAndContrast={addCompanreAndContrast}
          isContrast={pdfReducer.comparePdfs.some(
            (it) => it.name === selectedOption
          )}
          onUpload={uploadPdf}
        />
      }
      header={<Header open={open} setOpen={setOpen} onUpload={uploadPdf} />}
      sx={{ "& main": { maxHeight: "100vh" } }}
    >
      <Center
        h="100%"
        mih={0}
        w="100%"
        pl={{ sm: 350, lg: 300 }}
        pt="0"
        bg={"transparent"}
      >
        <ChatSection name={selectedOption} />
      </Center>
    </AppShell>
  );
};

export default Index;

import { useState, useEffect } from "react";
import { Center, Box, Divider, Flex, Text } from "@mantine/core";
import { IconChevronUp, IconChevronDown } from "@tabler/icons-react";

type Props = {
  value: number;
  addItems: (val: any) => void;
};

const UpperLimit = 20;
const ResultSetting = ({ value, addItems }: Props) => {
  const [state_value, setValue] = useState(0);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const updateContext = (val: number) => {
    addItems({ top_k: val });
  };

  const handleDecrement = () => {
    if (state_value > 0) {
      updateContext(state_value - 1);
      setValue((v) => v - 1);
    }
  };
  const handleIncrement = () => {
    if (state_value < UpperLimit) {
      updateContext(state_value + 1);
      setValue((v) => v + 1);
    }
  };

  return (
    <Box p="lg" w="100%">
      <Text pb="md" size="lg" fw={400} color="navy.0">
        Result
      </Text>
      <Flex
        bg="extra.0"
        w="100%"
        sx={{ borderRadius: 16, backgroundColor: "#E9F0FA" }}
      >
        <Flex p="lg">
          <Text size="sm" color="navy.0" sx={{ flexGrow: 1 }}>
            Set the number of top results the chatbot will provide for each
            query
          </Text>
          <Center>
            <Text size="lg" pl="sm" color="navy.0" fw="bold">
              {state_value}
            </Text>
          </Center>
        </Flex>
        <Divider orientation="vertical" />
        <Flex align="center" direction="column">
          <Center
            px="md"
            h="50%"
            onClick={handleDecrement}
            sx={{
              cursor: state_value === 0 ? "not-allowed" : "pointer",
              opacity: state_value === 0 ? 0.5 : 1,
            }}
          >
            <IconChevronUp />
          </Center>
          <Divider w="100%" />
          <Center
            px="md"
            h="50%"
            onClick={handleIncrement}
            sx={{
              cursor: state_value === UpperLimit ? "not-allowed" : "pointer",
              opacity: state_value === UpperLimit ? 0.5 : 1,
            }}
          >
            <IconChevronDown />
          </Center>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ResultSetting;

import * as React from "react";
import { SVGProps } from "react";
const SvgBot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={31}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.95 5.795V9.3H12.4c-4.915 0-9.066 3.267-10.4 7.75H0v6.2h2C3.333 27.733 7.485 31 12.4 31h6.2c4.915 0 9.066-3.267 10.4-7.75h2v-6.2h-2c-1.334-4.483-5.485-7.75-10.4-7.75h-1.55V5.805a3.107 3.107 0 1 0-3.1-.01ZM4.65 20.15a7.75 7.75 0 0 1 7.75-7.75h6.2a7.75 7.75 0 0 1 7.75 7.75v.02c0 4.26-3.47 7.73-7.75 7.73h-6.2a7.75 7.75 0 0 1-7.75-7.75ZM18.6 18.6v3.1h3.1v-3.1h-3.1Zm-9.3 0v3.1h3.1v-3.1H9.3Z"
      fill="#000"
    />
  </svg>
);
export default SvgBot;

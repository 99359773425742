import React from "react";

const MemoryIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.8875 11.6125L18.3875 4.1125C18.2723 4.00006 18.1365 3.91093 17.9875 3.85C17.8325 3.78702 17.6673 3.75312 17.5 3.75H7.5C6.50544 3.75 5.55161 4.14509 4.84835 4.84835C4.14509 5.55161 3.75 6.50544 3.75 7.5V22.5C3.75 23.4946 4.14509 24.4484 4.84835 25.1517C5.55161 25.8549 6.50544 26.25 7.5 26.25H22.5C23.4946 26.25 24.4484 25.8549 25.1517 25.1517C25.8549 24.4484 26.25 23.4946 26.25 22.5V12.5C26.251 12.3355 26.2194 12.1724 26.1572 12.0201C26.095 11.8678 26.0034 11.7293 25.8875 11.6125ZM11.25 6.25H16.25V8.75H11.25V6.25ZM18.75 23.75H11.25V20C11.25 19.6685 11.3817 19.3505 11.6161 19.1161C11.8505 18.8817 12.1685 18.75 12.5 18.75H17.5C17.8315 18.75 18.1495 18.8817 18.3839 19.1161C18.6183 19.3505 18.75 19.6685 18.75 20V23.75ZM23.75 22.5C23.75 22.8315 23.6183 23.1495 23.3839 23.3839C23.1495 23.6183 22.8315 23.75 22.5 23.75H21.25V20C21.25 19.0054 20.8549 18.0516 20.1517 17.3483C19.4484 16.6451 18.4946 16.25 17.5 16.25H12.5C11.5054 16.25 10.5516 16.6451 9.84835 17.3483C9.14509 18.0516 8.75 19.0054 8.75 20V23.75H7.5C7.16848 23.75 6.85054 23.6183 6.61612 23.3839C6.3817 23.1495 6.25 22.8315 6.25 22.5V7.5C6.25 7.16848 6.3817 6.85054 6.61612 6.61612C6.85054 6.3817 7.16848 6.25 7.5 6.25H8.75V10C8.75 10.3315 8.8817 10.6495 9.11612 10.8839C9.35054 11.1183 9.66848 11.25 10 11.25H17.5C17.8315 11.25 18.1495 11.1183 18.3839 10.8839C18.6183 10.6495 18.75 10.3315 18.75 10V8.0125L23.75 13.0125V22.5Z"
        fill="#2C4860"
      />
    </svg>
  );
};

export default MemoryIcon;

import { FC } from "react";
import {
  Box,
  Header,
  Button,
  FileButton,
  Flex,
  Burger,
  Text,
  MediaQuery,
} from "@mantine/core";
import { IconCloudUpload } from "@tabler/icons-react";
import SelectGpt from "./SelectGpt";
import UserMenu from "./UserMenu";

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  onUpload: (file: File | null) => void;
}

const HeaderContent: FC<Props> = ({ open, setOpen, onUpload }) => {
  return (
    <Header bg="transparent" p="sm" px="md" height={80} sx={{zIndex:100}}>
      <Flex w="100%" gap={8} direction="row" align="center">
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Flex p="sm" px="md" align="center" w="fit-content">
            <Burger
              opened={open}
              onClick={() => setOpen(!open)}
              size="sm"
              // mr="xl"
            />
          </Flex>
        </MediaQuery>

        {/* <FileButton onChange={onUpload} accept="application/pdf">
          {(props) => (
            <Button
              w={{ base: 280, sm: 310, lg: 260 }}
              color="navy.0"
              radius="md"
              size="lg"
              {...props}
              sx={{
                background: "linear-gradient(42deg, #642FD5 0%, #AA3ADF 100%)",
                boxShadow: " 0px 4px 18px 0px rgba(156, 63, 249, 0.48)",
              }}
            >
              <IconCloudUpload size={30} color="white" stroke={1.5} />
              <Text ml={8}>Upload PDF</Text>
            </Button>
          )}
        </FileButton> */}
        <SelectGpt />
        <Box sx={{ flexGrow: 1 }} />
        <UserMenu />
      </Flex>
    </Header>
  );
};

export default HeaderContent;

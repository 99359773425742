import { Flex, Avatar, Box, Loader, useMantineTheme } from "@mantine/core";
import { BotIcon } from "assets/icons";

const MessageLoading = () => {
  const { colors } = useMantineTheme();
  return (
    <Box maw="80%">
      <Flex gap={16} w="fit-content">
        <Avatar
          size="lg"
          sx={{
            border: 1,
            borderStyle: "solid",
            borderColor: colors.gray[5],
            borderRadius: "50%",
          }}
        >
          <BotIcon />
        </Avatar>
        <Box
          p={16}
          bg="navy.7"
          sx={{
            border: 1,
            borderStyle: "solid",
            borderColor: colors.gray[4],
            borderRadius: 8,
          }}
        >
          <Loader color="navy.0" />
        </Box>
      </Flex>
    </Box>
  );
};

export default MessageLoading;

import { FC, useContext, useState } from "react";
import {
  Modal,
  Flex,
  Grid,
  Text,
  useMantineTheme,
  MantineProvider,
} from "@mantine/core";

// local imports
import { Match } from "components";
import LanguageSetting from "./LanguageSetting";
import MemorySetting from "./MemorySetting";
import TemperatureSetting from "./TemperatureSetting";
import ResultSetting from "./ResultSetting";
import { SettingContext } from "context/setting";
import { useDispatch, useSelector } from "react-redux";

import { StoreState } from "store";
import { addItems } from "store/slices/settingSlice";
//icons imports
import Temperature from "assets/icons/Temperature";
import MemoryIcon from "assets/icons/MemoryIcon";
import LanguageIcon from "assets/icons/LanguageIcon";
import FileIcon from "assets/icons/FileIcon";
import ResultsIcon from "assets/icons/ResultsIcon";

interface Props {
  opened: boolean;
  onClose: () => void;
}

const SettingsModal: FC<Props> = ({ opened, onClose }) => {
  const { colors } = useMantineTheme();
  // const tabs = ["Temperature", "Memory", "Language", "Results"];
  const tabs = [
    { title: "Temperature", Icon: Temperature },
    { title: "Memory", Icon: MemoryIcon },
    { title: "Language", Icon: LanguageIcon },
    { title: "Results", Icon: ResultsIcon },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].title);

  const dispatch = useDispatch();
  const {
    settingReducer: { settings: state },
  } = useSelector<StoreState, StoreState>((state) => state);

  const handleAddItems = (val: any) => {
    dispatch(addItems(val));
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      withCloseButton={false}
      radius="lg"
      size="auto"
      sx={{
        "& .mantine-Modal-body": {
          background: "linear-gradient(223deg, #EFFFFB 0%, #EBE8FA 100%)",
          zIndex:1000
        },
        "& .mantine-Overlay-root": {
          background: "rgba(125, 125, 125, 0.47)",
          backdropFilter: "blur(27px)",
        },
      }}
    >
      <Grid
        mih="50vh"
        w={{ base: "90vw", sm: "80vw", md: "70vw", lg: "50vw", xl: "40vw" }}
      >
        <Grid.Col
          span={4}
          sx={{
            border: 0,
            borderRight: 1,
            borderColor: colors.gray[4],
            borderStyle: "solid",
          }}
        >
          <Flex direction="column" gap={8}>
            {tabs.map((tab) => (
              <Text
                key={tab.title}
                p="md"
                size="lg"
                bg={activeTab === tab.title ? "#FFF" : ""}
                fw={activeTab === tab.title ? 700 : 400}
                color="navy.0"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  boxShadow:
                    activeTab === tab.title
                      ? "0px 4px 18px 0px rgba(0, 0, 0, 0.10)"
                      : "",
                  borderRadius: 12,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor:
                      activeTab !== tab.title ? colors.extra[1] : undefined,
                  },
                }}
                onClick={() => setActiveTab(tab.title)}
              >
                <tab.Icon /> <Text>{tab.title}</Text>
              </Text>
            ))}
          </Flex>
        </Grid.Col>
        <Grid.Col span={8}>
          <Match test={activeTab}>
            <Match.Case value="Temperature">
              <TemperatureSetting
                value={state?.temperature ?? 0}
                addItems={handleAddItems}
              />
            </Match.Case>
            <Match.Case value="Memory">
              <MemorySetting
                value={state?.memory ?? 0}
                addItems={handleAddItems}
              />
            </Match.Case>
            <Match.Case value="Language">
              <LanguageSetting
                value={state?.language ?? "English"}
                addItems={handleAddItems}
              />
            </Match.Case>
            <Match.Case value="Results">
              <ResultSetting
                value={state?.top_k ?? 0}
                addItems={handleAddItems}
              />
            </Match.Case>
          </Match>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default SettingsModal;

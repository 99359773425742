import { call, put, takeEvery } from "redux-saga/effects";
import { Api } from "apis";
import { setPdfs } from "store/slices/pdfSlice";

function* fetchPdfs(): any {
  try {
    const resp: any = yield call(() => Api.getPdfs());
    yield put(setPdfs(resp?.data?.namespaces));
  } catch (e) {
    yield put(setPdfs([]));
  }
}

export function* mySaga() {
  yield takeEvery("pdfSlice/getPdfs", fetchPdfs);
}

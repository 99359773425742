import { FC } from "react";
import {
  Navbar,
  Text,
  Radio,
  Flex,
  Box,
  Divider,
  Button,
  FileButton,
} from "@mantine/core";
import UploadIcon from "assets/icons/UploadIcon";
import Softsensor from "assets/icons/svg/Softsensor";

interface Props {
  open: boolean;
  options: Array<string>;
  selectedOption: string;
  addCompareAndContrast: (name: string) => void;
  setSelectedOption: (val: string) => void;
  isContrast: boolean;
  onUpload: (file: File | null) => void;
}

const Sidebar: FC<Props> = ({
  open,
  options,
  selectedOption,
  setSelectedOption,
  addCompareAndContrast,
  isContrast,
  onUpload,
}) => {
  const handleContrast = () => {
    addCompareAndContrast("bpcl_bank_of_baroda_compare_contrast");
  };

  return (
    <Navbar
      hiddenBreakpoint="sm"
      hidden={!open}
      w={{ base: 400, sm: 350, lg: 300 }}
      bg="transparent"
      sx={{ borderBottomRightRadius: "25px", zIndex: 99 }}
    >
      <Radio.Group
        value={selectedOption}
        onChange={setSelectedOption}
        sx={{ minHeight: 0 }}
      >
        <Flex
          direction="column"
          w="100%"
          gap={10}
          p={20}
          h="100%"
          sx={{ overflowY: "auto" }}
        >
          {options.map((option) => (
            <Radio
              key={option}
              value={option}
              label={
                <Text
                  pl={8}
                  fw={500}
                  color="navy.0"
                  sx={{
                    cursor: "pointer",
                    wordBreak: "break-word",
                    textTransform: "capitalize",
                  }}
                >
                  {option}
                </Text>
              }
              py={16}
              px={32}
              w="100%"
              color="navy.0"
              bg={option === selectedOption ? "#DFE9FD" : undefined}
              sx={{ borderRadius: 10 }}
            />
          ))}
        </Flex>
      </Radio.Group>
      <Box sx={{ flexGrow: 1 }} />
      <Flex w="100%" p={20}>
        <Radio
          value="cc"
          checked={isContrast}
          label={
            <Text pl={8} fw={500} color="navy.0">
              Compare & Contrast
            </Text>
          }
          onChange={handleContrast}
          mt="auto"
          py={16}
          px={32}
          w="100%"
          color="navy.0"
          bg={isContrast ? "#DFE9FD" : undefined}
          sx={{ borderRadius: 10 }}
        />
      </Flex>
      <Divider sx={{ width: "100%" }} />
      <Flex w="100%" p={20}>
        <FileButton onChange={onUpload} accept="application/pdf">
          {(props) => (
            <Button
              w={{ base: 280, sm: 310, lg: 260 }}
              color="navy.0"
              radius="md"
              size="lg"
              {...props}
              sx={{
                background: "linear-gradient(42deg, #642FD5 0%, #AA3ADF 100%)",
                boxShadow: " 0px 4px 18px 0px rgba(156, 63, 249, 0.48)",
              }}
            >
              <UploadIcon />
              <Text ml={8}>Upload PDF</Text>
            </Button>
          )}
        </FileButton>
      </Flex>
      <Flex w="100%" py={8} px={20} align="center" gap="1rem">
        <Text size="md">Powered by</Text>
        <Softsensor />
      </Flex>
    </Navbar>
  );
};

export default Sidebar;
